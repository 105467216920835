<template>
  <v-dialog v-model="dialog" width="500">
    <template #activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        class="mr-1"
        small
        v-bind="attrs"
        v-on="on"
        @click="openDialog()"
      >
        New Map View
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span>Add Map View</span>
      </v-card-title>
      <v-divider />
      <ValidationObserver v-slot="{ invalid }">
        <v-card-text class="pt-5">
          <v-form>
            <v-row>
              <v-col cols="12" class="py-0">
                <Autocomplete
                  v-model="selectedProject"
                  item-value="id"
                  clearable
                  label="Project Name"
                  no-filter
                  :cache-items="false"
                  resource="projects"
                  rules="required"
                  dense
                />
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Project id"
                  rules="required"
                >
                  <v-text-field
                    v-model="floor.projectId"
                    dense
                    disabled
                    outlined
                    :error-messages="errors"
                    label="Project id"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Floor/Site Name"
                  rules="required"
                >
                  <v-text-field
                    v-model="floor.name"
                    dense
                    outlined
                    :error-messages="errors"
                    label="Floor/Site Name"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Map Style"
                  rules="required"
                >
                  <v-select
                    v-model="floor.mapStyle"
                    :items="mapStyle"
                    label="Map Style"
                    dense
                    outlined
                    :error-messages="errors"
                    item-value="value"
                    item-text="name"
                  />
                </ValidationProvider>
              </v-col>
              <v-row class="px-3">
                <v-col cols="6" class="py-0">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="East"
                    rules="required|number"
                  >
                    <v-text-field
                      v-model="floor.east"
                      dense
                      outlined
                      :error-messages="errors"
                      label="East"
                      type="number"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" class="py-0">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="West"
                    rules="required|number"
                  >
                    <v-text-field
                      v-model="floor.west"
                      dense
                      outlined
                      :error-messages="errors"
                      label="West"
                      type="number"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row class="px-3">
                <v-col cols="6" class="py-0">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="North"
                    rules="required|number"
                  >
                    <v-text-field
                      v-model="floor.north"
                      dense
                      outlined
                      :error-messages="errors"
                      label="North"
                      type="number"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="6" class="py-0">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="South"
                    rules="required|number"
                  >
                    <v-text-field
                      v-model="floor.south"
                      dense
                      outlined
                      :error-messages="errors"
                      label="South"
                      type="number"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-col cols="12" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Image URL"
                  rules="required|hyperlink"
                >
                  <v-text-field
                    v-model="floor.image"
                    dense
                    outlined
                    :error-messages="errors"
                    label="Image URL"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Cancel </v-btn>
          <v-btn
            color="primary"
            :loading="savingLoadingIndicator"
            text
            :disabled="invalid"
            @click="addNewFloor()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import Autocomplete from "@evercam/shared/components/Autocomplete"
import { MAP_STYLES } from "@/components/constants"

export default {
  components: {
    Autocomplete,
  },
  props: {
    refreshMapProjectsList: {
      type: Function,
      default: () => ({}),
    },
    mapProjects: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      selectedProject: null,
      floor: {
        projectId: null,
        projectName: null,
        name: null,
        east: null,
        west: null,
        north: null,
        south: null,
        image: null,
        mapStyle: null,
      },
      mapStyle: MAP_STYLES,
      savingLoadingIndicator: false,
    }
  },
  watch: {
    async selectedProject(project) {
      if (project) {
        this.floor.projectId = project.exid
        this.floor.projectName = project.name
      }
    },
  },
  methods: {
    openDialog() {
      this.floor = {
        projectId: null,
        projectName: null,
        name: null,
        east: null,
        west: null,
        north: null,
        south: null,
        image: null,
        mapStyle: null,
      }
      this.selectedProject = null
    },
    async addNewFloor() {
      try {
        this.savingLoadingIndicator = true
        let newFloor = {
          projectId: this.floor.projectId,
          projectName: this.floor.projectName,
          name: this.floor.name,
          east: parseFloat(this.floor.east),
          west: parseFloat(this.floor.west),
          north: parseFloat(this.floor.north),
          south: parseFloat(this.floor.south),
          image: this.floor.image,
          mapStyle: this.floor.mapStyle,
        }
        const currentMapView = this.mapProjects[this.floor.projectId] ?? []
        currentMapView.push(newFloor)
        await fetch(
          `${this.$config.public.firebaseDbLink}data/projects/maps/${this.floor.projectId}.json`,
          { method: "PUT", body: JSON.stringify({ ...currentMapView }) }
        )
        this.refreshMapProjectsList()
        this.$notifications.success("Floor successfully saved")
      } catch (error) {
        this.$notifications.error({ text: "Failed to save the floor!", error })
      } finally {
        this.savingLoadingIndicator = false
        this.dialog = false
      }
    },
  },
}
</script>
