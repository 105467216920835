const MAP_STYLES = [
  { name: "All", value: null },
  { name: "map", value: "terrain" },
  { name: "satellite", value: "hybrid" },
]

export default {
  projectName: {
    component: "TextFieldSearchFilter",
    offline: true,
  },
  name: {
    component: "TextFieldSearchFilter",
    offline: true,
  },
  projectId: {
    component: "TextFieldSearchFilter",
    offline: true,
  },
  mapStyle: {
    component: "SelectSearchFilter",
    attributes: {
      items: MAP_STYLES,
      value: null,
    },
    offline: true,
  },
}
